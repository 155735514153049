import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`1-High Hang Squat Snatch `}<em parentName="p">{`(pocket hang)`}</em></p>
    <p>{`+`}</p>
    <p>{`1-Hang Squat Snatch `}<em parentName="p">{`(above knee)`}</em></p>
    <p>{`+`}</p>
    <p>{`1-Full Snatch`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`12-Power Snatch (95/65)(RX+ 115/75)`}</p>
    <p>{`12-T2B`}</p>
    <p>{`12-OHS`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      